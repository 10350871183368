<template>
  <div class="">
    <div class="text-end">
      <button type="button" class="btn btn-secondary mb-3" @click.prevent="goToCreate('Admin')">新增訂單</button>
    </div>
    <div class="mb-5">
      <vue-good-table
        mode="remote"
        :columns="columns"
        :rows="rows"
        ref="table"
        :totalRows="totalRecords"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        :pagination-options="{
          enabled: true,
          perPage: 10,
          nextLabel: '下一頁',
          prevLabel: '上一頁',
          rowsPerPageLabel: 'Rows per page',
          allLabel: '全部',
          perPageDropdownEnabled: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'btn'">
            <button type="button" class="btn btn-main btn-sm mb-1" style="white-space:nowrap;" @click.prevent="goEdit(props.row.merchantTradeNo)">修改</button>
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
      </vue-good-table>
    </div>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
/* global $ */
import { mapActions, mapGetters } from 'vuex';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';

const Swal = require('sweetalert2');

export default {
  name: 'Teacher__Course',
  components: {
    VueGoodTable,
  },
  data() {
    return {
      page: 1,
      data: [],
      noData: false,
      tempCourse: {},
      is_enabled: true,
      createModalRole: '系統管理員',
      totalRecords: 100,
      serverParams: {
        columnFilters: { ItemName: '' },
        sort: [
          {
            field: 'merchantdate',
            type: 'DESC',
          },
        ],
        page: 1,
        perPage: 10,
      },
      columns: [
        {
          label: '品項',
          field: 'itemName',
          filterOptions: {
            styleClass: 'class1',
            enabled: true,
            placeholder: '搜尋品項',
            filterValue: '',
            filterFn: this.onColumnFilter,
            trigger: '',
          },
        },
        {
          label: '訂單建立時間',
          field: 'merchantDate',
        },
        {
          label: '金額',
          field: 'totalAmount',
          sortable: false,
        },
        {
          label: '付款方式',
          field: 'choosePayment',
          sortable: false,
        },
        {
          label: '發票號碼',
          field: 'invoiceNumver',
          sortable: false,
        },
        {
          label: '發票日期',
          field: 'invoiceDate',
        },
        {
          label: '動作',
          field: 'btn',
          sortable: false,
        },
      ],
      rows: [
        {
          id: 1, merchantDate: '2022-06-21T11:49:10', userId: '2d7267d4-8dad-454c-bae0-f0b30772b444', courseId: 1, totalAmount: 350, itemName: 'SOLIDWORKS 商品 * 1', choosePayment: 'Credict', invoiceNumver: 'AZ12345678', invoiceDate: '2022-06-21T11:50:55',
        },
      ],
      metaTitle: '訂單列表 / 訂單 / 管理 - 元學堂',
    };
  },
  metaInfo: {
    title: "訂單列表 / 訂單 / 管理 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '訂單列表 / 訂單 / 管理 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  methods: {
    goToCreate(role) {
      this.$router.push('/admin/order');
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
    },
    goEdit(id) {
      this.$router.push(`/admin/order/${id}`);
    },
    loadItems() {
      const vm = this;
      this.updateLoading(true);
      this.$http.post(`${this.$API_PATH}/Admin/Order/Management`, vm.serverParams, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        this.$log.debug(res.data.data.orders);
        this.updateLoading(false);
        this.data = res.data.data.orders.map((item, i) => {
          const merchantDate = item.merchantDate.split('T').join(' ');
          const invoiceDate = item.invoiceDate.split('T').join(' ');
          return {
            ...item,
            merchantDate,
            invoiceDate,
            choosePayment: item.choosePayment == 'Credict' || 'Credit' ? '信用卡' : item.choosePayment,
          };
        });
        this.rows = [...this.data];
      }).catch((err) => {
        this.updateLoading(false);
        if (err.response) {
          if (err.response.status == 401) {
            Swal.fire(
              '',
              '無權限取得列表',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                vm.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '',
            '錯誤，請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              vm.$router.push('/');
            }
          });
        }
      });
    },
    onColumnFilter(params) {
      const handleParams = { columnFilters: { ...params.columnFilters, ItemName: params.columnFilters.itemName } };
      this.updateParams(handleParams);
      this.loadItems();
    },
    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params[0].type,
          field: params[0].field,
        }],
      });

      this.loadItems();
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    onCellClick(param) {
      const vm = this;
      // console.log(param.row, param.column);
      // console.log(param.row.id);
      if (param.column.field == 'ena') {
        const query = {
          enable: !param.row.ena,
        };
        this.$http.put(`${this.$API_PATH}/Admin/Course/Management/Enable/${param.row.id}`, query, {
          headers: {
            Accept: '*/*',
            Authorization: `bearer ${this.userAuth}`,
            'Content-Type': 'application/json',
          },
        }).then((res) => {
          // console.log(res.data);
          this.getData();
        }).catch((err) => {
          this.updateLoading(false);
          if (err.response) {
            if (err.response.status == 401) {
              Swal.fire(
                '',
                '無權限取得列表',
                'info',
              ).then((result) => {
                if (result.isConfirmed) {
                  vm.$router.push('/');
                }
              });
            }
          } else {
            Swal.fire(
              '',
              '錯誤，請稍候再試',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                vm.$router.push('/');
              }
            });
          }
        });
      } else if (param.column.field == 'btn') {
        if (param.event.target.innerText == '管理') {
          this.$router.push(`/admin/course/${param.row.id}`);
        }
      }
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
  },
  created() {
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    // console.log(this.userAuth);
    if (this.userAuth.length == 0) {
      this.$router.push('/login');
    }
    this.loadItems();
  },
};
</script>

<style lang="scss" scoped>
  .pageNum {
    cursor: pointer;
    text-decoration: none;
    &.disabled {
      pointer-events: none;
      color: gray;
      cursor: default;
    }
  }
  ::v-deep #vgt-table td span {
    line-height: 1.4rem;
  }
  ::v-deep #vgt-table tbody tr td:nth-of-type(3) {
    font-size: .9rem;
  }

  ::v-deep #vgt-table thead th {
    min-width: 95px !important;
    padding-right: .75rem !important;
  }

</style>
