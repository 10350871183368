var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"text-end"},[_c('button',{staticClass:"btn btn-secondary mb-3",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.goToCreate('Admin')}}},[_vm._v("新增訂單")])]),_c('div',{staticClass:"mb-5"},[_c('vue-good-table',{ref:"table",attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.rows,"totalRows":_vm.totalRecords,"pagination-options":{
        enabled: true,
        perPage: 10,
        nextLabel: '下一頁',
        prevLabel: '上一頁',
        rowsPerPageLabel: 'Rows per page',
        allLabel: '全部',
        perPageDropdownEnabled: false,
      }},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'btn')?_c('span',[_c('button',{staticClass:"btn btn-main btn-sm mb-1",staticStyle:{"white-space":"nowrap"},attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.goEdit(props.row.merchantTradeNo)}}},[_vm._v("修改")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1),_c('Meta',{attrs:{"title":_vm.metaTitle}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }